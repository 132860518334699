function digObject(target, keyString, defaultValue){
  const keys = keyString.split('.')

  const value = keys.reduce(
    (acc, key) => {
      const accValue = typeof acc === 'undefined' || acc === null ? acc : acc[key]
      return accValue
    },
    { ...target },
  )

  if (value === null || value === undefined) return defaultValue

  return value
}

export default digObject
