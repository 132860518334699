/* eslint-disable no-param-reassign */
import isMobileBrowser from 'is-mobile'

const isMobile = isMobileBrowser()

const getElementParams = (element) => {
  const { contentEditable, readOnly } = element

  return {
    contentEditable,
    readOnly,
  }
}

const resetElementParams = (element, initParams) => {
  const { contentEditable, readOnly } = initParams

  element.contentEditable = contentEditable
  element.readOnly = readOnly
}

const copyValue = (inputRef, callback) => {
  const element = inputRef ? inputRef.current : null

  if (element){
    // Mobile
    if (isMobile){
      const selection = window.getSelection()
      const range = document.createRange()

      const initParams = getElementParams(element)

      element.contentEditable = true
      element.readOnly = false
      range.selectNodeContents(element)
      selection.removeAllRanges()
      selection.addRange(range)
      element.setSelectionRange(0, 999999)
      resetElementParams(element, initParams)
    }

    // Desktop
    if (!isMobile){
      element.select()
    }

    document.execCommand('copy')

    if (callback) callback()
  }
}

function useCopyInputValue(){
  return {
    callbacks: {
      copyValue: (inputRef, callback) => copyValue(inputRef, callback),
    },
  }
}

export default useCopyInputValue
