function findArrayIndex(array, value, options = {}){
  const { deepCompare, useObjectKey } = options

  // Replace items with the same key
  if (useObjectKey){
    return array.findIndex(item => item[useObjectKey] === value[useObjectKey])
  }

  // Deep Compare - compare objects by stringifying them
  if (deepCompare){
    return array.findIndex(item => JSON.stringify(item) === JSON.stringify(value))
  }

  // Simple Array
  return array.findIndex(item => item === value)
}

export default findArrayIndex