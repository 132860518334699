import { useSelector } from 'react-redux'

function useWatchEntityUpdates(watchEntityKeys, options = {}){
  const { comparisonKey, customUseSelectorFn } = options || {}

  // eslint-disable-next-line no-underscore-dangle
  const useSelectorFn = customUseSelectorFn || useSelector
  const entityUpdates = useSelectorFn(reduxState => reduxState.entities._updates)

  const comparisonUpdate = comparisonKey && entityUpdates[comparisonKey] ? entityUpdates[comparisonKey] : 0

  const updatedEntityKeys = Object.keys(entityUpdates).filter((key) => {
    const entitiesUpdatedAt = entityUpdates[key]

    // Only stale if updated more than 500 ms ago
    const isStale = comparisonKey ? (entitiesUpdatedAt - comparisonUpdate) > 500 : true

    return (watchEntityKeys.includes(key) && isStale)
  })

  const updatedEntities = updatedEntityKeys.reduce((acc, key) => {
    acc[key] = entityUpdates[key]
    return acc
  }, {})

  return {
    updatedEntities,
    updatedEntityKeys,
  }
}

export default useWatchEntityUpdates
