import findArrayIndex from './findArrayIndex'

function toggleArray(array, value, options = {}){
  const { useObjectKey } = options

  const updatedArray = array ? JSON.parse(JSON.stringify([...array])) : []

  const index = findArrayIndex(updatedArray, value, options)

  if (index !== -1){
    // useObjectKey replaces the original object if match found
    if (useObjectKey){
      updatedArray.splice(index, 1, value)
      return updatedArray
    }

    updatedArray.splice(index, 1)
  }

  if (index === -1){
    updatedArray.push(value)
  }

  return updatedArray
}

export default toggleArray