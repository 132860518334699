function parsePermittedQueryParams(queryParams, permittedKeys){
  const permittedParams = Object.keys(queryParams).reduce((acc, key) => {
    if (permittedKeys.includes(key)){
      const value = queryParams[key]
      acc[key] = Number.isNaN(Number(value)) ? value : Number(value)
    }
    return acc
  }, {})
  return permittedParams
}

export default parsePermittedQueryParams