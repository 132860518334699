import { useEffect, useState } from 'react'

function useOutsideClick(ref, options = {}){
  const { enabled = true, preventDefault = false } = options || {}

  const [isClickedOutside, setIsClickedOutside] = useState(false)

  const performClickOutside = (event) => {
    if (preventDefault){
      event.preventDefault()
    }

    if (ref.current && !ref.current.contains(event.target)){
      setIsClickedOutside(true)
    }
  }

  useEffect(() => {
    if (enabled){
      document.addEventListener('click', performClickOutside, true)
    }

    return () => document.removeEventListener('click', performClickOutside, true)
  }, [enabled])

  return [isClickedOutside, setIsClickedOutside]
}

export default useOutsideClick
